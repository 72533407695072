import Vue from 'vue'

class VideonContainer {
  constructor(container_guid, container_attributes = false) {
    this.container_guid = container_guid
    
    this.error = false
    this.errorText = ''
    
    this.identifier = (container_attributes && container_attributes.identifier) || ''
    
    this.name = (container_attributes && container_attributes.name) || ''
    this.image_url = (container_attributes && container_attributes.image_url) || ''
    this.description = (container_attributes && container_attributes.description) || ''
    
    this.companyName = (container_attributes && container_attributes.company.name) || ''
    this.companyUrl = (container_attributes && container_attributes.company.url) || ''
    this.companyDescription = (container_attributes && container_attributes.company.description) || ''
    
    this.requirements = (container_attributes && container_attributes.requirements) || ''
    this.manifest = (container_attributes && container_attributes.manifest) || {}
    
    this.platforms = (container_attributes && container_attributes.platforms) || []
    
    this.published = (container_attributes && container_attributes.published) || false
    this.licensable = (container_attributes && container_attributes.licensable) || false
    
    this.last_api_request_id = ''
    
    if (!container_attributes) {
      this.ready = false
      
      this.fetchContainer()
    } else {
      this.ready = true
    }
  }

  refresh() {
    this.fetchContainer()
  }

  delete() {
    this.container_guid = false
    Vue.delete(this)
  }

  fetchContainer() {
    if (!this.container_guid) {
      return
    }
    
    Vue.axios.get('/containers/' + this.container_guid)
    .then((response) => {
      console.log('VideonContainer', this.container_guid, 'fetchContainer response', response)
      this.last_api_request_id = response.data.api_request_id
      
      var { identifier, name, image_url, description, company, requirements, manifest, platforms, published, licensable } = response.data.container
      
      this.identifier = identifier
      
      this.name = name
      this.image_url = image_url
      this.description = description
      
      this.companyName = company.name
      this.companyUrl = company.url
      this.companyDescription = company.description
      
      this.requirements = requirements
      this.manifest = manifest
      
      this.platforms = platforms
      
      this.published = published
      this.licensable = licensable
      
      this.ready = true
      this.error = false
      this.errorText = ''
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
    })
  }
}

export default new class VideonContainers {
  constructor() {
    this.containers = []
  }

  reset() {
    console.log('VideonContainers reset')

    // invalidate container_guids
    for (var container of this.containers) {
      console.log('VideonContainers reset invalidating', container.container_guid)
      container.delete()
    }

    this.containers.splice(0)
  }

  invalidateContainer(container_guid) {
    for (var i = this.containers.length - 1; i >= 0; --i) {
      if (this.containers[i].container_guid == container_guid) {
        console.log('VideonContainers invalidateContainer', container_guid)
        this.containers[i].delete()
        this.containers.splice(i, 1)
        return
      }
    }
  }

  allContainers() {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/containers?unpublished=true')
      .then((response) => {
        console.log('Containers allContainers response', response)

        var newContainerList = response.data.containers.map(
          (container) => Vue.observable(new VideonContainer(container.container_id, container))
        )

        this.containers = newContainerList

        resolve(this.containers)
      }).catch((error) => {
        console.log('Containers allContainers error', error)
        reject(error)
      })
    })
  }

  getContainer(container_guid, container_attributes = false) {
    if (!container_guid) {
      console.log('Containers getContainer missing container_guid', container_guid, container_attributes)
      return false
    }

    var container = this.containers.find(x => x.container_guid == container_guid)
    if (container) {
        return container
    }

    container = Vue.observable(new VideonContainer(container_guid, container_attributes))
    this.containers.push(container)

    return container
  }

  getContainers() {
    return []
  }
}