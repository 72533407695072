<template>
  <v-container>
    <v-toolbar
      flat
      dense
      :color="($vuetify.theme.dark) ? '' : 'grey lighten-5'"
    >
      <span class="subtitle-2 mr-3">Identifier</span> {{ identifier }}
    </v-toolbar>
    <v-row>
      <v-col cols="12" class="">
        <v-textarea
          class="mt-3"
          label="Configuration JSON"
          v-model="shadowItem.JSONConfig"
          @input="configChanged"
          :disabled="!canEdit"
          rows="10"
          outlined
          hide-details="auto"
        >
        </v-textarea>
      </v-col>
    </v-row>
    
    <div class="mt-5 text-right" v-if="canEdit">
      <v-btn
        color="primary"
        class="ma-1"
        @click="saveShadow()"
      >
        Save
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'ConfigureContainer',
    
    props: [
      'device_guid', 
      'container', 
      'canEdit',
      'isOnline',
      'isProcessing',
      'actionedContainer',
      'showDeleteContainer',
    ],
    
    data() {
      return {
        documents: [],
        hasChanges: false,
        
        configJSON: ''
      }
    },
    
    computed: {
      deviceShadow() {
        if (this.device_guid) {
          return this.$deviceShadows.getDeviceShadow(this.device_guid, 'Containers')
        }
        
        return false
      },
      
      loading() {
        if (this.deviceShadow && (!this.deviceShadow.ready || this.deviceShadow.updatePending)) {
          return true
        }
        
        return false
      },
      
      updatePending() {
        if (this.deviceShadow && this.deviceShadow.updatePending) {
          return true
        }
        
        return false
      },
      
      requestPending() {
        if (this.deviceShadow && this.deviceShadow.requestPending) {
          return true
        }
        
        return false
      },
      
      error() {
        if (this.deviceShadow && this.deviceShadow.error) {
          return true
        }
        
        return false
      }, 
      
      errorText() {
        if (this.deviceShadow && this.deviceShadow.errorText) {
          return this.deviceShadow.errorText
        }
        
        return false
      },
      
      identifier() {
        if (this.container.identifier) {
          return this.container.identifier
        } else {
          return this.$containers.getContainer(this.container.container_id).identifier + '_1' // TODO remove this fudge
        }
      },
      
      shadowItem() {
        var shadowItem = this.documents.find((config) => config.id == this.identifier)
        
        if (!shadowItem) {
          shadowItem = {'id': this.identifier, 'config': {}}
        } 
        
        shadowItem.JSONConfig = JSON.stringify(shadowItem.config)
        
        return shadowItem
      },
    },
    
    mounted() {
      this.deviceShadow.editing = true
      
      var documents = this.deviceShadow.editableStateCopy()
      if (documents) {
        this.documents = documents
        this.hasChanges = false
      }
    },
    
    beforeDestroy() {
      this.deviceShadow.editing = false
    },
    
    watch: {
      deviceShadow: {
        handler() {
          if (!this.hasChanges) {
            var documents = this.deviceShadow.editableStateCopy()
            if (documents) {
              this.documents = documents
            }
          }
        }, 
        deep: true
      }
    },
    
    methods: {
      configChanged() {
        
      },
      
      saveShadow() {
        console.log('ConfigureContainer ' + this.device_guid + ' saveShadow ' + this.container.id, this.shadowItem)
        
        var itemIndex = this.documents.findIndex((config) => config.id == this.identifier)
        if (itemIndex > 0) {
          this.documents[itemIndex].config = JSON.parse(this.shadowItem.JSONConfig)
        } else {
          this.documents.push({'id': this.identifier, 'config': JSON.parse(this.shadowItem.JSONConfig)})
        }
        console.log('documents', this.documents)
        
        var state = this.documents.map((config) => {
          return {'id': config.id, 'config': config.config}
        })
        
        this.deviceShadow.saveShadow(state)
        this.hasChanges = false
      }
    }
  }
</script>