<template>
  <div>
    <v-dialog
      v-model="showCreateContainer"
      max-width="640px"
    >
      <videon-card heading="Create Container" showClose="true" @close="showCreateContainer = false">
        <v-card-text>
          <v-form @submit.prevent="createContainer">
            <v-text-field
              v-model="createContainerName"
              label="Container Name"
              autocomplete="off"
              :rules="$validations.containerNameValidation"
              required
              outlined
            ></v-text-field>
            
            <v-select
              v-model="createContainerImageID"
              :items="images"
              itemValue="id"
              label="Container Image"
              hide-details
              outlined
            >
              <template slot="selection" slot-scope="data">
                {{ $helpers.repositoryName(data.item) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ $helpers.repositoryName(data.item) }}
              </template>
            </v-select>
            
            <edit-manifest
              v-model="createContainerManifest"
              :hasPrivileged="hasPrivileged"
              :isAdmin="isAdmin"
              :canEdit="canEdit"
            ></edit-manifest>
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateContainer = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createContainer"
                :disabled="!isCreateContainerValid"
              >
                Create Container
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showConfirmDeleteContainer"
      max-width="480px"
    >
      <videon-card heading="Delete Container" showClose="true" @close="showConfirmDeleteContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to delete <span class="primary--text">{{ selectedContainer.container_id && $containers.getContainer(selectedContainer.container_id).name || selectedContainer.name }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showConfirmDeleteContainer = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmDeleteContainer"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card flat>
      <v-card-title>
        Installed Containers
        
        <v-spacer/>
        
        <v-btn
          small
          outlined
          class="mr-1"
          color="secondary"
          @click="refreshContainers"
          tooltip="Reload Containers"
          :disabled="loading"
        >
          Reload
        </v-btn>
        
        <v-btn
          small
          color="primary"
          outlined
          @click="showCreateContainer = true"
          tooltip="Create Container"
          :disabled="!canEdit || !isOnline || isProcessing"
        >
          <v-icon>mdi-plus</v-icon> Create Container
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="containerHeaders"
          :items="containers"
          hide-default-footer
          disable-pagination
          :expanded.sync="expanded"
          :loading="loading"
          loadingText="Loading containers..."
          no-data-text="No containers"
          :items-per-page="200"
        >
          <template v-slot:item.id="{ item }">
            <div class="text-truncate" style="max-width: 110px;">
               {{ item.id }}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <div class="text-truncate" style="max-width: 200px;">
               {{ item.container_id && $containers.getContainer(item.container_id).name || item.name }}
            </div>
          </template>
          <template v-slot:item.image="{ item }">
            <span v-if="containerImageName(item)">{{ containerImageName(item) }}</span>
            <div v-else class="text-truncate text-center" style="max-width: 200px;">
               {{ item.image }}
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="text-capitalize">
               {{ $helpers.getLabel(item.status) }}
            </div>
          </template>
          <template v-slot:item.manage="{ item }">
            <v-btn
              @click="expandItem(item)"
              small
              text
            >
              Manage
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pt-4 pb-3">
              <div style="min-height: 300px;">
                <v-tabs
                  v-model="expandedTabs[item.container_id]"
                  color="primary"
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#manage">
                    Manage Container
                  </v-tab>
                  <v-tab href="#config" v-if="configurationSupported(item)">
                    Configure Container
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="expandedTabs[item.container_id]">
                  <v-tab-item value="manage">
                    <manage-container 
                      :device_guid="device_guid" 
                      :container="item" 
                      :isOnline="isOnline"
                      :isProcessing="isProcessing"
                      :actionedContainer="actionedContainer"
                      :canEdit="canEdit"
                      :showDeleteContainer="true"
                      @containerAction="containerAction"
                      @deleteContainer="deleteContainer"
                    />
                  </v-tab-item>
                  <v-tab-item value="config">
                    <configure-container 
                      :device_guid="device_guid" 
                      :container="item" 
                      :isOnline="isOnline"
                      :isProcessing="isProcessing"
                      :actionedContainer="actionedContainer"
                      :canEdit="canEdit"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import EditManifest from '../../../container/components/EditManifest.vue'
  
  import ManageContainer from './ManageContainer.vue'
  import ConfigureContainer from './ConfigureContainer.vue'
  
  export default {
    name: 'DockerContainersList',
    
    props: [
      'device_guid',
      'loading',
      'hasPrivileged',
      'isAdmin',
      'isOnline',
      'isProcessing',
      'actionedContainer',
      'images',
      'containers',
      'canEdit'
    ],
    
    components: {
      EditManifest,
      ManageContainer,
      ConfigureContainer
    },
    
    data() {
      return {
        noContainers: false,
        
        showCreateContainer: false,
        
        selectedContainer: false,
        
        showConfirmDeleteContainer: false,
        
        containerHeaders: [
          { text: 'Container ID', align: 'start', value: 'id', sortable: false },
          { text: 'Name', align: 'start', value: 'name', sortable: false },
          { text: 'Image', align: 'start', value: 'image', sortable: false },
          { text: 'Status', align: 'center', value: 'status', sortable: false },
          { text: '', align: 'right', value: 'manage', sortable: false},
        ],
        
        createContainerName: '',
        createContainerImageID: '',
        createContainerManifest: null,
        
        expanded: [],
        expandedTabs: {},
      }
    },

    computed: {
      isCreateContainerValid() {
        if (this.$validations.isValidContainerName(this.createContainerName)) {
          return true
        }
        
        return false
      }
    },

    methods: {
      expandItem(item) {
        var itemIndex = this.expanded.indexOf(this.expanded.find(x => x.id == item.id))
        
        if (itemIndex !== -1) {
          this.expanded.splice(itemIndex, 1)
        } else {
          this.expanded.push(item)
        }
      },
      
      configurationSupported(item) {
        if (item.identifier) {
          return true
        }
        return false
      },
      
      containerImageName(item) {
        var image = this.images.find(x => x.id === item.image)
        if (image) {
          return this.$helpers.repositoryName(image)
        }
        return false
      },
      
      createContainer() {
        // ensure the 2 required items are valid
        if (!this.createContainerImageID || !this.createContainerName) {
          return
        }
        
        this.showCreateContainer = false
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.ports &&
          this.createContainerManifest.ports.length > 0
        ) {
          for (var port of this.createContainerManifest.ports) {
            if (!port.host_port || !port.container_port) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.environment &&
          this.createContainerManifest.environment.length > 0
        ) {
          for (var envVar of this.createContainerManifest.environment) {
            if (!envVar.key) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.volumes &&
          this.createContainerManifest.volumes.length > 0
        ) {
          for (var volume of this.createContainerManifest.volumes) {
            if (!volume.host_path || !volume.container_path || !volume.mode) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        if (
          this.createContainerManifest &&
          this.createContainerManifest.devices &&
          this.createContainerManifest.devices.length > 0
        ) {
          for (var device of this.createContainerManifest.devices) {
            if (!device.host_path || !device.container_path || !device.mode) {
              // TODO: Display an error message
              return
            }
          }
        }
        
        this.$emit('create', {
          name: this.createContainerName,
          imageId: this.createContainerImageID,
          ...this.createContainerManifest
        })
      },
      
      refreshContainers() {
        this.$emit("refresh")
      },
      
      containerAction(container, action) {
        this.$emit('containerAction', container, action)
      },
      
      
      
      deleteContainer(container) {
        console.log('DockerContainersList deleteContainer', container)
        
        this.selectedContainer = container
        this.showConfirmDeleteContainer = true
      },
      
      confirmDeleteContainer() {
        this.showConfirmDeleteContainer = false
        this.$emit('delete', this.selectedContainer)
      },
    }
  }
</script>